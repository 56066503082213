@import url('https://fonts.cdnfonts.com/css/poppins');
@import url('https://fonts.googleapis.com/css?family=Alata');


html,
body {
	min-width: 100%;
}

.container {
	position: relative;
	min-width: 100%;
	text-align: center;
	font-size: large;
}


.frst {
	margin-left: 30%;
	margin-right: 25%;
	position: absolute;

	padding-top: 25px;
	padding-left: 30px;
	padding-bottom: 15px;
	padding-right: 30px;

	border-bottom-right-radius: 30px;
	border-bottom-left-radius: 30px;

	text-align: center;
	font-size: 300%;

	font-family: 'Inter', sans-serif;
	color: #1d3557;
	font-weight: 600;
	/*text-shadow: 0px 4px 10px #f1faee;*/
	background: rgb(190, 243, 252);
	background: linear-gradient(90deg, rgba(190, 243, 252, 1) 7%, rgba(127, 221, 243, 1) 46%, rgba(93, 180, 238, 1) 100%);

}



.frst2 {
	margin-left: 38%;
	display: inline-block;
	padding-top: 30px;
	font-size: 300%;

	font-family: 'Inter', sans-serif;
	color: #1d3557;
	font-weight: bolder;
	margin-right: 10px;
	/*text-shadow: 0px 4px 10px #f1faee;*/

}

.bck2 {
	display: inline-block;
	font-size: 300%;

	font-family: 'Inter', sans-serif;
	color: #1d3557;
	font-weight: bolder;
	/*text-shadow: 0px 4px 10px #f1faee;*/
	background: rgb(190, 243, 252);
	background: linear-gradient(90deg, rgba(190, 243, 252, 1) 7%, rgba(127, 221, 243, 1) 46%, rgba(93, 180, 238, 1) 100%);


	margin-top: 30px;
	padding-bottom: 12px;
	padding-top: 3px;
	padding-left: 12px;
	padding-right: 12px;

	border-radius: 20px;

}

.text {
	font-size: 100%;
	text-align: left;
	line-height: 2;
	margin-bottom: 50px;
	margin-left: 5%;
	margin-right: 5%;
	line-height: 1.4;

}

.header {
	font-size: 3.3vw;
	text-align: center;

}

.textd {
	display: flex;

}

.side2 {
	font-size: 120%;
	text-align: center;
	line-height: 2;
	flex: 1
}

.side2>h3 {
	margin-top: 20%;

}

.side2>p {
	padding: 15%;
}

.side1 {
	width: 100%;
	height: auto;
	flex: 1
}

.imgo {
	width: 500px;
}

.loginB>p {
	margin-top: 0px;
	margin-bottom: 2px;
}

.loginB {
	font-family: "alata";
	color: #1d3557;
	background-color: white;
	font-weight: bold;
	border: 2px solid;
	border-radius: 6px;
}

.loginB:hover {

	background-color: #1d3557;
	color: #f1faee;
	font-weight: bold;
	border: 2px solid;
	border-radius: 6px;
}


.navbar {
	background-color: #a8dadc;
}

.container>h2 {
	text-align: center;
}

.input-container>input {

	border: 2px solid;
	border-radius: 4px;
	border-color: #1d3557;
	;
	margin: 8px 0;
	width: 30vw;

}

.delete-btn {
	background-color: whitesmoke;
	color: #e63946;
	margin: 8px;
	border: 2px solid;
	border-radius: 4px;
}

.add-btn {
	background-color: #1d3557;
	color: #f1faee;
	border-radius: 4px;
}

/*card */
.cards {
text-align: center;}

.icon {
	margin-top: 10px;
}

.card1 {
	display: inline-block;
	margin-left: 5%;
	margin-bottom: 20px;
	margin-top: 20px;
	border: 1px;
	border-style: solid;
	border-color: #a8dadc;
	text-align: center;
}


.ctitle {
	font-family: 'Inter', sans-serif;
	margin-bottom: 20px;
}

.ctext {
	font-family: 'Poppins', sans-serif;

}

/*Navbar*/
.link {
	font-family: "alata";
	font-weight: 500;
	margin-left: 50px;
	margin-right: 43px;
	/*background: #bef3fc;*/

	border-top-left-radius: 15px;
	border-top-right-radius: 15px;


}


/*Positions*/
@import url('https://fonts.cdnfonts.com/css/poppins');

.options {
	font-family: 'Inter', sans-serif;
}

.options h1 {
	text-align: center;
	margin-bottom: 40px;
	font-weight: bolder;
	color: #1d3557;
}

.options p {
	font-size: 100%;
	text-align: left;
	line-height: 2;
	margin-bottom: 50px;
	margin-left: 5%;
	margin-right: 5%;
	line-height: 1.4;

}




.lists {
	display: flex;
	text-align: center;
	margin-left: 7%;

}

.pos1 h2 {
	color: #1d3557;
	margin-bottom: 1px;
}

.pos1 h3 {
	color: #1d3557;
}

.pos2 {
	margin-right: 10%;
	text-align: left;
}

.pos2 h2 {
	color: #1d3557;
	margin-bottom: 1px;

}

.pos2 h3 {
	color: #1d3557;
}

.pos1 {
	text-align: left;
}

.senior {
	margin-right: 2%;

}

.cabin {
	margin-right: 2%;
	margin-left: 5%;


}


/*proecss*/
.process {
	text-align: center;
	font-family: 'Inter', sans-serif;
	margin-top: 5%;
	margin-bottom: 5%;

	color: #1d3557
}

.process h1 {
	font-weight: bolder;
}


/* Container for the process */
.timeline .process-container {
	width: 100%;
	max-width: 90%;
	margin: 0 auto;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #1d3557
}

.timeline .process-container h1 {
	text-align: center;
	font-family: 'Inter', sans-serif;

	font-weight: bolder;
}

/* Title styling */
.timeline h1 {
	margin-bottom: 50px;
	color: #2c3e50;
	font-size: 36px;
}

/* Flex container to make the steps horizontal */
.timeline .steps {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0;
	list-style: none;
	width: 100%;
	margin: 0 auto;
	position: relative;
}

/* Horizontal line between steps */
.timeline .steps::before {
	content: '';
	position: absolute;
	width: 90%;
	height: 4px;
	background-color: #1d3557;
	top: 50px;
	/* Align with the circles */
	left: 5%;
	z-index: -1;
}

/* Individual step styling */
.timeline .step {
	position: relative;
	text-align: left;
	padding-top: 30px;
}

/* Circle with number inside */
.timeline .circle {
	width: 40px;
	height: 40px;
	background-color: #1d3557;
	border-radius: 50%;
	color: white;
	font-size: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	position: relative;
	z-index: 1;
}

/* Lines between the circles */
.timeline .circle::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 4px;
	background-color: #1d3557;
	top: 50%;
	left: 100%;
	z-index: -1;
}

/* Remove the line from the last step */
.timeline .step:last-child .circle::before {
	display: none;
}

/* Step description styling */
.timeline .description {
	padding-left: 10px;
	color: #7f8c8d;
	font-size: 16px;
	width: 180px;
	margin-left: 35%;
	margin-top: 10%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
	.timeline .steps {
		flex-direction: column;
		align-items: center;
	}

	.timeline .steps::before {
		display: none;
	}

	.timeline .step {
		margin-bottom: 20px;
		text-align: center;
	}

	.timeline .circle::before {
		display: none;
	}

	.timeline .description {
		text-align: center;
		padding-left: 0;
		margin-top: 10px;
	}
	
	
}


/*forms*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Inter', sans-serif;
}




.myform {
	background-color: #6a64f1;

}

.c {
	color: white;
	text-align: center;
	margin-bottom: 0%;
	margin-right: 29%;
	padding-top: 2%;
}

.t {
	color: white;
	text-align: center;
	margin-bottom: 0%;
	margin-right: 3%;
	padding-top: 2%;
}

.formbold-mb-3 {
	margin-bottom: 15px;
}

.formbold-main-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 48px;
	padding-top: 2%;

}

.formbold-form-wrapper {
	margin: 0 auto;
	max-width: 570px;
	width: 100%;
	background: white;
	padding: 40px;
}

.formbold-img {
	display: block;
	margin: 0 auto 45px;
}

.formbold-input-wrapp>div {
	display: flex;
	gap: 20px;
}

.formbold-input-flex {
	display: flex;
	gap: 20px;
	margin-bottom: 15px;
}

.formbold-input-flex>div {
	width: 50%;
}

.formbold-form-input {
	width: 100%;
	padding: 13px 22px;
	border-radius: 5px;
	border: 1px solid #dde3ec;
	background: #ffffff;
	font-weight: 500;
	font-size: 16px;
	color: #536387;
	outline: none;
	resize: none;
}

.formbold-form-input::placeholder,
select.formbold-form-input,
.formbold-form-input[type='date']::-webkit-datetime-edit-text,
.formbold-form-input[type='date']::-webkit-datetime-edit-month-field,
.formbold-form-input[type='date']::-webkit-datetime-edit-day-field,
.formbold-form-input[type='date']::-webkit-datetime-edit-year-field {
	color: rgba(83, 99, 135, 0.5);
}

.formbold-form-input:focus {
	border-color: #6a64f1;
	box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-form-label {
	color: #07074D;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	display: block;
	margin-bottom: 10px;
}

.formbold-form-file-flex {

	align-items: center;
	justify-content: center;
	gap: 20px;
}

.formbold-form-file-flex .formbold-form-label {
	margin-bottom: 0;
}

.formbold-form-file {
	font-size: 14px;
	line-height: 24px;
	color: #536387;
	margin-bottom: 3%;
	margin-top: 2%;
}

.formbold-form-file::-webkit-file-upload-button {
	display: none;
}

.formbold-form-file:before {
	content: 'Upload file';
	display: inline-block;
	background: #EEEEEE;
	border: 0.5px solid #FBFBFB;
	box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
	padding: 3px 12px;
	outline: none;
	white-space: nowrap;
	-webkit-user-select: none;
	cursor: pointer;
	color: #637381;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	margin-right: 10px;
}

.formbold-btn {
	text-align: center;
	width: 100%;
	font-size: 16px;
	border-radius: 5px;
	padding: 14px 25px;
	border: none;
	font-weight: 500;
	background-color: #6a64f1;
	color: white;
	cursor: pointer;
	margin-top: 25px;
}

.formbold-btn:hover {
	box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-w-45 {
	width: 45%;
}


/*form validation error message*/
.error-message {
	color: red;
	/* Text color for error message */
	font-size: 14px;
	/* Font size of the error message */
	margin-top: 5px;
	/* Adds space above the message */
	font-weight: bold;
	/* Makes the message stand out */
}

.error-message::before {
	content: '⚠️ ';
	/* Adds a warning icon before the message */
}

.error-message {
	display: block;
	/* Ensure the error message is displayed on a new line */
}

input.error {
	border: 2px solid red;
	/* Highlight input fields with errors */
}


/*radio buttons for form*/



/*login*/
.logb {
	text-align: center;
	font-family: "alata";
	font-size: 600%;
}

.logb h1 {

	margin-top: 10%;

}

.lgbtn {
	padding-left: 5%;
	padding-right: 5%;
	color: white;
	background-color: #1d3557;
	font-size: 30%;

}

.lgbtn:hover {
	background-color: white;
	color: #1d3557;

}

/*Dashboard*/
.h1 {
	margin-top: 1%;
	margin-bottom: 1%;
	color: #1d3557;
	font-family: "alata";
}

.sideboard {
	min-height: 10vh;
	background-color: pink;
}

.sidebar-content {
	color: white;
}

.body-dash {
	display: flex;
	flex-wrap: wrap;
}

.table {
	font-family: "alata";
}

.dash-table {
	flex: 50%;
	width: 100%;
	margin-left: 1%;
}

.update {
	margin-left: 3%;
}

/*spacer*/
.spacer {
	display: flex;
	background-color: #1d3557;
	margin-top: 4%;
	margin-bottom: 4%;
	color: aliceblue;
	font-size: 200%;
	font-weight: bold;


}

.conter1 .conter2 {
	font-family: 'alata';
	color: aliceblue;
	flex-wrap: nowrap;
	display: flex;
}

.conter1 {
	margin-right: 21%;
	margin-left: 20%;
	margin-top: 1%;
	margin-bottom: 1%;
}

.conter2 {
	margin-right: 11%;
	margin-left: 18%;
	margin-top: 1%;
	margin-bottom: 1%;
}

/*Contact*/
.contact {
	display: flex;
	background-color: #1d3557;
	text-align: center;

	color: #f1faee;
}

.contact>h3 {
	padding-top: 2%;
	margin-left: 20%;
	margin-right: 3%;
}

.contact-btn {
	background-color: #f1faee;
	color: #1d3557;

}

.cb {
	margin-right: 5%;
	margin-top: 2%;
	margin-bottom: 2%;

}

/*Footer*/
.footer img {
	height: 30px;
	width: auto;
	margin-right: 1%;
}

/*Details page*/
/* General container styling for the details page */
.details {
	max-width: 800px;
	margin: 20px auto;
	padding: 20px;
	background-color: #f9f9f9;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	font-family: 'Arial', sans-serif;
}

.details h1 {
	font-size: 24px;
	color: #2c3e50;
	margin-bottom: 30px;
	text-align: center;
	font-size: xx-large;
	font-weight: bolder;
	color: #1d3557;
}

/* Style the applicant details section */
.det {
	margin-bottom: 30px;
}

.det p {
	font-size: 16px;
	margin: 10px 0;
	color: #34495e;
	line-height: 1.5;
}

.det p b {
	color: #3498db;
}

/* Styling for the image of the applicant */
/* Centering the image directly under the title */
.details img {
	display: block;
	/* Make the image behave as a block element */
	margin: 20px auto;
	/* Center the image horizontally and add vertical space */
	border-radius: 50%;
	/* Make the image circular */
	border: 3px solid #3498db;
	/* Add a blue border */
	width: 145px;
	/* Set a fixed width */
	height: 145px;
	/* Set a fixed height */
}

/* Document section styling */
.details h2 {
	font-size: 20px;
	color: #2c3e50;
	margin-top: 40px;
	margin-bottom: 20px;
}

/* Button styling */
.details a {
	text-decoration: none;
}

.details button {
	font-size: 16px;
	padding: 10px 20px;
	margin: 5px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;
}

/* Different button colors for various document types */
.details .btn-success {
	background-color: #2ecc71;
	color: white;
}

.details .btn-success:hover {
	background-color: #27ae60;
}

.details .btn-warning {
	background-color: #f39c12;
	color: white;
}

.details .btn-warning:hover {
	background-color: #e67e22;
}

.details button:hover {
	opacity: 0.9;
}

/* Responsive adjustments */
@media (max-width: 768px) {
	.details {
		padding: 15px;
	}

	h1 {
		font-size: 20px;
	}

	.det p {
		font-size: 14px;
	}

	details button {
		font-size: 14px;
		padding: 8px 16px;
	}
}


/*loading*/
.loader-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	/* Ensures loader is centered vertically */
}

.loader {
	border: 8px solid #f3f3f3;
	border-top: 8px solid #3498db;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loader-container p {
	margin-top: 10px;
	font-size: 18px;
	color: #555;
}

@media (max-width : 768px) {

	.frst {
		display: none;
		padding: 0%;
		margin: 0%;
	}

	.frst2 {
		padding: 0%;
		margin-right: 1%;
		margin-left: 14%;
		font-size: 200%;
		padding-top: 1%;
	}

	.bck2 {
		font-size: 200%;
		margin-top: 1%;
	}
	.text{
		text-align: center;
	}
	.why{
		text-align: center;}
/*cards*/
	.card1 {
	}

	.spacer {

		flex-direction: column;
		/* Stack items vertically */
		align-items: center;
	}
	.conter1{
text-align: center;
	}
	.conter2{
text-align: center;
margin-left: 10%;

	}

	.lists {
		display: flex;
		flex-direction: column;
		/* Stack items vertically */
		align-items: center;
	}

	.options h1 {
		font-size: 250%;
		font-weight: bolder;
	}
	.options p{
		text-align: center;
	}
	.options h2{
		text-align: center;
	}
	.options h3{
		text-align: center;
	}

}